import { motion } from "framer-motion";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { ReadMore } from "../../components/ReadMore";

export const Teaching = () => {
  return (
    <Container>
      <h3
        style={{
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        Teaching
      </h3>
      <div className="publications-container">
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5 }}
          // viewport={{ once: true }}
        >
          <Card>
            <Card.Header>McMaster University</Card.Header>
            <Card.Body>
              <Card.Title className="justify-text bold">
                Teaching Assistant
              </Card.Title>
              {/* <Card.Subtitle className="mb-2 text-muted">
                        {publication.date}
                    </Card.Subtitle> */}
              <Card.Text className="justify-text">
                <span className="bold">Fall 2024: </span>
                Designated by the course instructor as the lead TA for CIV ENG
                2P04-Statics and Mechanics of Materials
                <br />
                <ul>
                  <li>
                    Coordination of weekly TA tasks to ensure efficient and
                    consistent instruction across the various sections.
                  </li>
                  <li>Design of learning resources.</li>
                  <li>
                    Delivery of weekly tutorials and office hours for 205
                    students
                  </li>
                  <li>
                    Was appointed by the course instructor as the Project-based
                    learning (PBL) TA: The instructor designed the course in a
                    PBL format, where students work in teams to accomplish the
                    analysis of a structural systems that integrates all the
                    concepts of statics and mechanics---from load estimation to
                    stress transformation (Mohr’s circle) and analysis of
                    members subjected to multiple loading. In this quest, the
                    instructor appointed me as the PBL-TA, exclusively dedicated
                    to the PBL-learning. Benefiting from computational skills
                    blended with my love of civil engineering, I developed a
                    software tool to automate the solution of the project. The
                    instructor designed a project where students work in teams,
                    each team has different input values for the loads,
                    structural geometry, and material properties. Using this
                    software, I have integrated all the calculations involved
                    the calculation of loads, the transfer of load across the
                    various structural members, as well the analysis of the
                    structural members.
                  </li>
                </ul>
                <span className="bold">Fall 2023: </span>
                Teaching Assistant for CIV ENG 2P04-Statics and Mechanics of
                Materials
                <ul>
                  <li>Design of learning resources.</li>
                  <li>
                    Delivery of weekly tutorials and office hours for 178
                    students
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </motion.div>
      </div>
    </Container>
  );
};
