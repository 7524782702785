import React from "react";
// import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from "react-router-dom";

import { NavBar } from "./components/NavBar";
import { Home } from "./pages/Home/Home";
import { Publications } from "./pages/PublicationsPage/Publications";
import { Awards } from "./pages/AwardsPage/Awards";
import { Developments } from "./pages/DevelopmentsPage/Developments";
import { PhotoGallery } from "./pages/PhotoGallery/PhotoGallery";
import { Teaching } from "./pages/Teaching/Teaching";
// import { Contact } from "./pages/ContactPage/Contact";

import { Footer } from "./components/Footer";

//Load the css file for bootstrap library. This is a MUST for bootstrap to work
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/GeneralCSS.css"; // Import this AFTER bootstrap

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWHvyM3MF11agjQBjo0CjjaPm-8Ei014M",
  authDomain: "mohamad-tarabin-portfolio.firebaseapp.com",
  projectId: "mohamad-tarabin-portfolio",
  storageBucket: "mohamad-tarabin-portfolio.appspot.com",
  messagingSenderId: "929228136950",
  appId: "1:929228136950:web:93186193d70ea5c7a667d7",
  measurementId: "G-J2WNQZC75H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/research" element={<Publications />} />
        <Route path="/teaching" element={<Teaching />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/developments" element={<Developments />} />
        <Route path="/photogallery" element={<PhotoGallery />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
